import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/client';

export type GlobalModalId =
  | 'multipleModal'
  | 'auth'
  | 'survey'
  | 'inquiry'
  | 'webViewNotification'
  | 'noticeModal'
  | 'premiumSurvey'
  | 'modernBrowser'
  | 'mobileAppLaunchModal'
  | 'mobileAppLaunchSheet'
  | 'mobileV2SurveySheet'
  | 'paymentCompleteModalSheet'
  | 'premiumExpansion'
  | 'landbookEdu'
  | 'articleShow'
  | 'socialSignUp'
  | 'certification'
  | 'kakaoMapServiceDisableNotice3'
  | 'articleServiceCheckNotice'
  | 'networkError'
  | 'recommandLight'
  | 'appVersionUpdate'
  | 'marketPrice'
  | 'membershipSuccess'
  | 'unableServiceModal'
  | 'membershipChatModal'
  | 'notiEventTicketModal';

type GlobalModalStaticInfo = {
  id: GlobalModalId;
  priority: number;
  required: boolean;
};

type GlobalModalMap = {
  [key in GlobalModalId]: GlobalModalStaticInfo;
};

export const globalModalMap: GlobalModalMap = {
  socialSignUp: {
    id: 'socialSignUp',
    priority: 1,
    required: true,
  },
  certification: {
    id: 'certification',
    priority: 1,
    required: true,
  },
  paymentCompleteModalSheet: {
    id: 'paymentCompleteModalSheet',
    priority: 1,
    required: true,
  },
  auth: {
    id: 'auth',
    priority: 2,
    required: true,
  },
  articleServiceCheckNotice: {
    id: 'articleServiceCheckNotice',
    priority: 3,
    required: true,
  },
  kakaoMapServiceDisableNotice3: {
    id: 'kakaoMapServiceDisableNotice3',
    priority: 3,
    required: true,
  },
  survey: {
    id: 'survey',
    priority: 3,
    required: true,
  },
  inquiry: {
    id: 'inquiry',
    priority: 3,
    required: true,
  },
  premiumSurvey: {
    id: 'premiumSurvey',
    priority: 4,
    required: true,
  },
  webViewNotification: {
    id: 'webViewNotification',
    priority: 4,
    required: true,
  },

  mobileAppLaunchSheet: {
    id: 'mobileAppLaunchSheet',
    priority: 5,
    required: true,
  },
  mobileV2SurveySheet: {
    id: 'mobileV2SurveySheet',
    priority: 5,
    required: true,
  },
  recommandLight: {
    id: 'recommandLight',
    priority: 6,
    required: true,
  },
  appVersionUpdate: {
    id: 'appVersionUpdate',
    priority: 7,
    required: false,
  },
  marketPrice: {
    id: 'marketPrice',
    priority: 8,
    required: false,
  },
  membershipSuccess: {
    id: 'membershipSuccess',
    priority: 9,
    required: false,
  },
  unableServiceModal: {
    id: 'unableServiceModal',
    priority: 9,
    required: false,
  },
  membershipChatModal: {
    id: 'membershipChatModal',
    priority: 9,
    required: false,
  },
  notiEventTicketModal: {
    id: 'notiEventTicketModal',
    priority: 9,
    required: false,
  },
  /**
   * `required: false`는 선택 모달이고, 아래의 룰로 제공됩니다.
   * - 1일 1회
   * - '확인' 클릭 시: 24시간 뒤 재노출
   * - '다시 보지 않기' 클릭 시: 스토리지 정보 제거 전까지 미노출
   */

  multipleModal: {
    id: 'multipleModal',
    priority: 2,
    required: false,
  },

  landbookEdu: {
    id: 'landbookEdu',
    priority: 2,
    required: false,
  },
  articleShow: {
    id: 'articleShow',
    priority: 2,
    required: false,
  },
  networkError: {
    id: 'networkError',
    priority: 3,
    required: false,
  },
  noticeModal: {
    id: 'noticeModal',
    priority: 4,
    required: false,
  },
  premiumExpansion: {
    id: 'premiumExpansion',
    priority: 5,
    required: false,
  },
  mobileAppLaunchModal: {
    id: 'mobileAppLaunchModal',
    priority: 5,
    required: false,
  },
  modernBrowser: {
    id: 'modernBrowser',
    priority: 6,
    required: false,
  },
};

export type RegisteredModal = {
  id: GlobalModalId;
  options: any;
};

export type RegisteredModalWithInfo = RegisteredModal & GlobalModalStaticInfo;

interface GlobalModalState {
  registeredModals: RegisteredModal[];
}

const initialState: GlobalModalState = {
  registeredModals: [],
};

const globalModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    register: (
      state,
      action: PayloadAction<{
        id: GlobalModalId;
        options?: any;
      }>
    ) => {
      const { id, options } = action.payload;
      const index = state.registeredModals.findIndex(modal => modal.id === id);
      if (index === -1) {
        state.registeredModals.push({ id, options });
      } else {
        state.registeredModals.splice(index, 1, { id, options });
      }
    },

    unregister: (
      state,
      action: PayloadAction<{
        id: GlobalModalId;
      }>
    ) => {
      const index = state.registeredModals.findIndex(
        modal => modal.id === action.payload.id
      );

      if (index !== -1) {
        state.registeredModals.splice(index, 1);
      }
    },
  },
});

export const { register, unregister } = globalModalSlice.actions;
export const selectGlobalModal = (state: RootState) => state.globalModal;
export default globalModalSlice.reducer;
