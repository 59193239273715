import { TokenInfo } from 'apis/auth';
import { FilterType } from 'apis/filter';
import { MapType } from 'apis/map';
import { LocalDocument, SeachHistory } from 'apis/search';
import { SelectType } from 'components/Analytics/_comps/PremiumOption/PremiumOptionProvider';
import { PreviewParcel } from 'components/ParcelLocationMap/PreviewCard/shared';
import { FailedPremiumRequestMap } from 'services/InAppPurchase/useFailedPremiumRequest';
import { RecentMapRecord } from './map';
import storage from 'store2';
import { getTimeStamp } from 'utils/dateUtil';

import type { DateFormat } from 'utils/dateUtil';
import { NumberOfPageVisits } from 'hooks/useTrackLocation';

/**
 * @todo 더이상 쓰이지 않는 키는 여기로 옮겨 진입 시 1회 정리해주기
 * 모달 키가 계속 늘어나 사용자 스토리지에 쌓이고 있음
 */
export const DEPRECATED_KEYS = [
  'closeCityPlanModalSheetAt',
  'modal:articleShowPromotion',
  'modal:promotionSpecialModal',
  'modal:articleShow2nd',
  'modal:landbookShowPromotion',
  'modal:agreementNotice',
  'isDesktopV3',
  'isAnalytics',
  'isPy',
  'enableCurrentAppUntil',
  'recentPriceUnit',
  'viewedOptionalModalAt',
  'VIEW_PREMIUM_UPDATE_FAILED_MESSAGE',
  'LAST_SEEN_PARCEL_ID',
  'enableCurrentDesktopUntil',
  'modal:desktopVersionGuideModal',
  'modal:agreementNotice-220625',
  'modal:premiumExpansion-인천',
];

export const removeDeprecatedStorageKeys = (cleanupDate: DateFormat) => {
  if ('requestIdleCallback' in window) {
    const CLEANUP_STORAGE_KEY = 'deprecatedKeyCleanupTime';
    const currentCleanupTime = getTimeStamp(cleanupDate);
    const lastCleanupTime = storage.get(CLEANUP_STORAGE_KEY, -1);

    if (lastCleanupTime < currentCleanupTime) {
      requestIdleCallback(() => {
        DEPRECATED_KEYS.forEach(key => storage.remove(key));
        storage.set(CLEANUP_STORAGE_KEY, currentCleanupTime);
      });
    }
  }
};

interface SessionValueMap {
  /** 본인인증 모달 확인 */
  isOpenCertificationModal: boolean;
  socialSignInToken: string;
  'modal:unableServiceModal': boolean;
}

export type SessionValueKeys = keyof SessionValueMap;

export const SESSION_VALUES: SessionValueKeys[] = ['isOpenCertificationModal'];

interface LocalValueMap {
  RECENT_SEARCH: LocalDocument[];
  ADDRESS_SEARCH_HISTORY: SeachHistory[];
  spwkSession: TokenInfo;
  nextAccessLogSessionTime: number;
  recentMapRecord: RecentMapRecord;
  openModernBrowserAt: number;
  'tooltip:memebershipMain': number;
  'modal:multipleModal': number;
  'modal:mobileAppLaunchDesktop': number;
  'modal:mobileAppLaunchSheet': number;
  /** 메인 홈의 가이드 모달 오픈 여부 */
  'modal:mainHomeGuide': boolean;

  /** 랜드북 매물 서비스 점검 안내*/
  'modal:articleServiceCheckNotice': boolean;
  /** 카카오 서버 장애로 인한 지도서비스 이용불가 안내 */
  'modal:kakaoMapServiceDisableNotice3': boolean;
  [x: `modal:articleShow-${string}`]: number;
  [x: `modal:landbookEdu-${string}`]: number;
  [x: `modal:agreementNotice-${string}`]: number;
  [x: `modal:premiumExpansion-${string}`]: number;
  pageRefreshed: boolean;
  lastSeenParcelLocation: PreviewParcel;
  lastSeenParcelId: { id: string; on: 'sheet' | 'map' };
  lastSeenNotification: Record<string, string>;
  viewPremiumAt: number;
  premiumOptionData: SelectType;
  activeSearchMapFilterTab: FilterType;
  RequestPremiumAnalysisFailure: FailedPremiumRequestMap;
  viewedPremiumUpdateFailedMessage: boolean;
  skipOptionalModalsForInternalTest: boolean;
  lastSeenCompletedPremium: Record<string, string>;
  numberOfPageVisits: NumberOfPageVisits;
  'tooltip:premiumList': boolean;
  'tooltip:mapType': MapType[];
  'coachMark:premiumList': boolean;
  'tooltip:premiumOverlay': boolean;
  'tooltip:nearByTrading': boolean;
  'modal:consulting': boolean;
  'tooltip:landbookFeatures': boolean;
  'modal:isAlreadyInsightModal': string[];
  'modal:isAlreadyInsightModalVaildTime': number;
  'tooltip:marketPriceHooking': boolean;
  'modal:plusTicketEvent': boolean;
}

/**
 * TODO: key format 통일
 */
interface StorageValueMap extends LocalValueMap, SessionValueMap {}

/**
 * TODO: 키 포멧 통일 필요
 */
export type StorageKeys = keyof StorageValueMap;

const storageUtil = {
  get: <T extends StorageKeys>(
    key: T,
    alt?: StorageValueMap[T]
  ): StorageValueMap[T] => storage.get(key, alt),
  set: <T extends StorageKeys>(key: T, value: StorageValueMap[T]) =>
    storage.set(key, value),
  has: <T extends StorageKeys>(key: T): boolean => storage.has(key),
  remove: <T extends StorageKeys>(key: T): boolean => storage.remove(key),

  page: {
    set: <T extends StorageKeys>(key: T, value: StorageValueMap[T]) =>
      storage.page.set(key, value),
    get: <T extends StorageKeys>(key: T, alt?: StorageValueMap[T]) =>
      storage.page.get(key, alt),
  },
  session: {
    set: <T extends StorageKeys>(key: T, value: StorageValueMap[T]) =>
      storage.session.set(key, value),
    get: <T extends StorageKeys>(key: T, alt?: StorageValueMap[T]) =>
      storage.session.get(key, alt),
    /**
     * 매개변수를 전달하면 전달된 키 리스트만 제거한다.
     * @param sessionValueKeys 제거할 키 리스트
     * @returns
     */
    clear: (sessionValueKeys?: SessionValueKeys[]) => {
      if (sessionValueKeys) {
        sessionValueKeys.forEach(key => {
          storage.session.remove(key);
        });
        return;
      }

      storage.session.clear();
    },
  },
};

/**
 * 다른 탭의 sessionStorage를 가지고 온다.
 */
export const getSessionStorage = () => {
  /**
   * 다른 문서에서 storage를 변경했을 때 발생한다.
   */
  const sessionStorageTransfer = (event: StorageEvent) => {
    if (!event.newValue) {
      return;
    }

    const isNewTab = !sessionStorage.length;

    // [FIRST_TAB] sessionStorage 송신
    if (event.key === 'getSessionStorage') {
      if (sessionStorage.length > 1) {
        // 다른 탭에서 sessionStorage를 요청했을 때 임시로 sessionStorage를 localStorage에 저장한다.
        localStorage.setItem(
          'landBooksessionStorage',
          JSON.stringify(sessionStorage)
        );
        // 송신을 마친 데이터를 제거
        localStorage.removeItem('landBooksessionStorage'); // <- could do short timeout as well.
      }

      localStorage.removeItem('getSessionStorage');
    } // [SECOND_TAB] sessionStorage 수신
    else if (event.key === 'landBooksessionStorage' && isNewTab) {
      const data = JSON.parse(event.newValue);
      for (let key in data) {
        if (SESSION_VALUES.includes(key as SessionValueKeys)) {
          sessionStorage.setItem(key, data[key]);
        }
      }
    }
  };
  window.addEventListener('storage', sessionStorageTransfer);

  localStorage.setItem('getSessionStorage', Date.now().toString());
};
export default storageUtil;
